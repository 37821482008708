<template>
    <div class="creatitem-main">
        <div class="creatitem-wrap">
            <div class="creatitem-wrap-title">新建方法类别</div>
            <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 6 }" class="form-main">
                <a-form-model-item label="名称">
                    <a-input v-model="form.name"/>
                </a-form-model-item>
                <a-form-item :wrapper-col="{ span: 6, offset: 6 }">
                    <a-button type="primary" @click="doClick()">
                        确定
                    </a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['reload', 'create_do', 'active_item'],
        name: "creatitem",
        data() {
            return {
                form: {
                    name: '',
                },
            };
        },
        watch: {},
        mounted() {

        },

        methods: {
            doClick() {
                let data = {
                    name: this.form.name,
                    pid: this.active_item.key,
                    type: this.active_item.create_type
                }
                console.log(data)
                this.$sa0.post({
                    url: this.$api('Create_method_category'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            this.reload()
                            this.form = {
                                name: ''
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
        }
    }
</script>

<style scoped>
    .creatitem-main{ margin-left: 20px; width: 1000px}

    .creatitem-wrap-title{ font-size: 18px; font-weight: bold; background: #fafafa; padding: 10px}

    .form-main{margin-top: 30px; width: 100%; }
</style>
