<script>
    /***
     Name: tree
     Code: sa0ChunLuyu
     Time: 2021/11/3 16:39
     Remark: 树组件
     */
    import Tree from './tree/tree'
    import Item from './item/item'

    export default {
        components: {Tree, Item},
        name: "zizilist",
        data() {
            return {
                active_item: false,
                tree_show: false,
                menu: [],
                fa_menu: [],
                tree: [],
                pid: 0
            }
        },
        mounted() {
            this.GMethod_category_alllist(),
                setTimeout(() => {
                    this.tree_show = true
                }, 100)
        },
        methods: {
            itemClick(e) {
            },

            // itemClick(e, item) {
            //     console.log(item.id)
            //
            // },


            itemDel(item) {
                let data = {
                    id: item.key,
                }
                console.log(data)
                this.$sa0.post({
                    url: this.$api('Del_method_category'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            this.GMethod_category_alllist()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            itemRename(item) {
                item.create_type = item.type
                this.active_item = item
                this.$refs.itemref.item_type = 1
                console.log(item.type)
                this.$refs.itemref.create_file_type = item.type
            },
            itemCreate(item, type) {
                item.create_type = type
                this.active_item = item
                this.$refs.itemref.item_type = 0
                this.$refs.itemref.create_file_type = type
            },
            makeList(list) {
                return list.map((item) => {
                    let menu = []
                    if (item.type === 0) {
                        let dir_menu = true
                        let item_menu = true
                        item.child.map((item) => {
                            if (item.type === 0) {
                                item_menu = false
                            }
                            if (item.type === 1) {
                                dir_menu = false
                            }
                        })
                        menu = [{
                            "title": '删除',
                            "func": (e, item) => {
                                this.itemDel(item)
                            }
                        }, {
                            "title": '重命名',
                            "func": (e, item) => {
                                console.log('重命名')
                                console.log('item', item)
                                this.itemRename(item)
                            },
                        }]
                        // if (Number(item.id) !== 126) {
                        //     menu = [{
                        //         "title": '删除',
                        //         "func": (e, item) => {
                        //             this.itemDel(item)
                        //         }
                        //     }, {
                        //         "title": '重命名',
                        //         "func": (e, item) => {
                        //             console.log('重命名')
                        //             console.log('item', item)
                        //             this.itemRename(item)
                        //         },
                        //     }]
                        // }

                        if (dir_menu) {
                            menu.push({
                                "title": '新建文件夹',
                                "func": (e, item) => {
                                    this.itemCreate(item, 0)
                                },
                            })
                        }
                        if (item_menu) {
                            menu.push({
                                "title": '新建内容',
                                "func": (e, item) => {
                                    this.itemCreate(item, 1)
                                },
                            })
                        }
                    } else {
                        menu = [{
                            "title": '删除',
                            "func": (e, item) => {
                                this.itemDel(item)
                            }
                        }, {
                            "title": '重命名',
                            "func": (e, item) => {
                                console.log('重命名')
                                console.log('item', item)
                                this.itemRename(item)
                            },
                        }]
                    }
                    return {
                        'key': item.id.toString(),
                        "children": item.child.length === 0 ? [] : this.makeList(item.child),
                        "title": item.name,
                        "menu": menu,
                        "pid": item.pid,
                        "type": item.type,
                    }
                })
            },
            GMethod_category_alllist() {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            toReload() {
                this.tree_show = false
                this.GMethod_category_alllist()
                setTimeout(() => {
                    this.tree_show = true
                })
            },
            createDir() {
                let item = {
                    key: 0,
                    create_type: 0
                }
                this.active_item = item
                this.$refs.itemref.item_type = 0
                this.$refs.itemref.create_file_type = 0
            }
        }
    }
</script>
<template>
    <div class="wrap-mainer">
        <div v-if="tree_show" class="menutree">
            <Tree :choose="itemClick" :createDir="createDir" :tree="tree" :menu="menu"></Tree>
        </div>
        <div class="wrap-container">
            <Item ref="itemref" :active_item="active_item" :reload="toReload"></Item>
        </div>
    </div>
</template>
<style scoped>
    .wrap-mainer{ width: 100%; display: flex;}

    .menutree{ margin-left: -20px; display: flex;}

    .wrap-container{ flex: 1; overflow-y: auto}
</style>
