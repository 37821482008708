<template>
    <div>
        <CreatItem v-if="item_type === 0  && create_file_type === 0" :create_do="create_do" :reload="reload" :active_item="active_item"></CreatItem>
        <Edititem v-if="item_type === 1  && create_file_type === 0" :create_do="create_do" :reload="reload" :active_item="active_item"></Edititem>
        <CreatItemneirong v-if="item_type === 0 && create_file_type === 1" :create_do="create_do" :reload="reload" :active_item="active_item"></CreatItemneirong>
        <Edititemneirong v-if="item_type === 1  && create_file_type === 1" :reload="reload" :active_item="active_item"></Edititemneirong>
    </div>
</template>
<script>
    import CreatItem from './creatitem/creatitem'
    import Edititem from './edititem/edititem'
    import CreatItemneirong from './creatitemneirong/creatitemneirong'
    import Edititemneirong from './edititemneirong/edititemneirong'
    export default {
        components: {CreatItem, Edititem, CreatItemneirong, Edititemneirong},
        props: ["reload", 'active_item'],
        name: "item",
        data() {
            return {
                item_type: 0,   //是否是新建或者编辑变量
                create_do: 1,   //
                create_file_type: 0 //是否是新建文件夹还是新建内容变量
            };
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo();
                },
                deep: true,
            },
        },
        mounted() {
            this.mountedDo();
        },
        methods: {
            mountedDo() {

            },
        }

    }
</script>

<style scoped>

</style>
