<script>
    /***
     Name: tree_item
     Code: sa0ChunLuyu
     Time: 2021/11/3 16:55
     Remark: 树节点
     */

    export default {
        name: 'treeItem',
        props: {
            menu_click: {
                type: String,
                default: ''
            },
            menu_show: {
                type: String,
                default: ''
            },
            changeMenuShow: {
                type: Function,
                default: () => {
                }
            },
            changeMenuClick: {
                type: Function,
                default: () => {
                }
            },
            item: {
                type: Object,
                default: {}
            },
            item_key: {
                type: Number,
                default: 0
            },
            deep: {
                type: Number,
                default: 0
            },
            menu: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                children_show: true,
                next_deep: 0,
            }
        },
        mounted() {
            this.next_deep = this.deep + 1
        },
        watch: {
            menu_show(val) {
                if (val !== `(${this.deep}),(${this.item_key})`) {
                }
            },
        },
        computed: {
            menu_list() {
                let list = [];
                if (this.menu.length > 0) {
                    list = list.concat(this.menu)
                }
                if (this.item.menu) {
                    if (this.item.menu.length > 0) {
                        list = list.concat(this.item.menu)
                    }
                }
                return list
            }
        },
        methods: {
            itemClick() {
                this.changeMenuClick(this.item.key.toString())
            },
            menuClick(m,item) {
                console.log(item)
                m.func(m,item)
            },
            onContextShow() {
                this.changeMenuShow(this.item.key)
            },
            onContextClose() {
                this.changeMenuShow('')
            },
        },
    }
</script>
<template>
    <div @mouseleave="onContextClose()" class="tree_item_wrapper">
        <div @click="itemClick()" class="ant-space-horizontal " :class="menu_click === item.key?'select':''">
            <div class="ant-space-horizontal-left">
                <div v-if="item.type === 1" @click="children_show=!children_show">
                    <a-icon type="file"/>
                </div>
                <div v-else @click="children_show=!children_show">
                    <a-icon :type="children_show?'folder-open':'folder'" theme="filled"/>
                </div>
                <div class="tree_item_wrapper-title">{{ item.title }}</div>
            </div>
            <div @mouseover="onContextShow()" class="tree_item_wrapper-more">
                <a-icon type="more"/>
            </div>
            <div class="menu_wrapper" v-if="menu_show === item.key">
                <div @click="menuClick(m,item)" class="menu_item_wrapper" v-for="m in menu_list">{{ m.title }}</div>
            </div>
        </div>
        <div v-if="item.children && item.children.length && children_show">
            <div v-for="(i,k) in item.children" :key="k" class="menuchild-wrap">
                <treeItem :menu_show="menu_show" :menu_click="menu_click" :changeMenuShow="changeMenuShow" :changeMenuClick="changeMenuClick" :item="i" :menu="menu" :item_key="k" :deep="next_deep"></treeItem>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .select{
        background: #daebfe !important;
        }
    .tree_item_wrapper{ height: 100%; background: #ffffff; box-sizing: border-box;}

    .menu_wrapper{
        border: #cccccc 1px solid;
        position: absolute;
        background: #ffffff;
        width: 100px;
        z-index: 999999;
        top: 0px;
        right: -90px;
        padding: 0px;
        border-radius: 4px; background: #404040; transition: 0.5s all; cursor: pointer;
        }
    .menu_item_wrapper{ color: #ffffff; padding: 5px; line-height: 30px; padding-left: 15px }

    .menu_item_wrapper:hover{ background: #666666}

    .menu_item_wrapper:hover .tree_item_wrapper-more{ display: block}


    .tree_item_wrapper{
        position: relative;
        line-height: 40px;
        border-left: 1px solid #dcd6d6;
        cursor: pointer; padding-left: 10px;
        }

    .ant-space-horizontal{ display: flex; justify-content: space-between; align-items: center; position:relative }

    .ant-space-horizontal-left{ display: flex;}

    .ant-space-horizontal:hover{ background: #f2f2f2}

    .tree_item_wrapper-title{ margin-left: 5px; vertical-align: middle; margin-top: 2px}

    .tree_item_wrapper-more{ width: 25px; display: none}

    .ant-space-horizontal:hover .tree_item_wrapper-more{ display: block}

    .menuchild-wrap{ background: #f1f8ff }





</style>
