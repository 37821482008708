<script>
    /***
     Name: tree
     Code: sa0ChunLuyu
     Time: 2021/11/3 16:44
     Remark: 树组件
     */
    import TreeItem from './tree_item'

    export default {
        components: {TreeItem},
        props: {
            tree: {
                type: Array,
                default: []
            },
            deep: {
                type: Number,
                default: 0
            },
            menu: {
                type: Array,
                default: []
            },
            createDir: {
                type: Function,
                default: () => {
                }
            },
            choose: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                menu_click: '',
                menu_show: '',
            }
        },
        mounted() {

        },
        methods: {
            menuHide() {
                if (this.menu_show !== '') {
                    this.menu_show = ''
                }
            },
            changeMenuShow(key) {
                this.menu_show = key
            },
            changeMenuClick(key) {
                this.menu_click = key
                this.choose(key)
            },
        }
    }
</script>
<template>
    <div class="left-menuer">
        <div class="menu-title">方法类别列表</div>
        <div class="left-menuer-main">
            <div @click="menuHide()" v-for="(item,key) in tree" :key="key" class="menumain">
                <TreeItem :item="item" :menu_show="menu_show" :menu_click="menu_click" :changeMenuShow="changeMenuShow" :changeMenuClick="changeMenuClick" :menu="menu" :item_key="key" :deep="deep"></TreeItem>
            </div>
        </div>
        <div class="CreatBtn" @click="createDir()">新建方法类别</div>
    </div>
</template>
<style scoped>
    .left-menuer{ width: 260px; border: 1px solid #eeeeee; height: calc(100vh - 120px); border-left: none;  flex-direction: column; display: flex}

    .menu-title{ width: 100%; background: #f9f9f9; font-size: 14px; font-weight: bold; padding: 10px}

    .left-menuer-main{   flex: 1}

    .menumain{ width: 100%; }

    .left-menu-btn{ width: 260px; background: #1890ff; color: #ffffff; text-align: center; line-height: 40px; position: absolute; bottom: 0; cursor: pointer}

    .CreatBtn{width: 100%; text-align: center; background: #1890ff; line-height: 40px; color: #ffffff; cursor: pointer}


    .left-menuer-main::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }
    .left-menuer-main::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background   : #eee;
        }
    .left-menuer-main::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 0px;
        background   : #fff;
        }

</style>
